<template>
  <div>
    <Menu direction="below" :closeOnItemClick="false">
      <template #label>
        <div class="filter filter-active">
          <div class="filter__label">
            <unicon
              :name="sortParam.order === 'up' ? 'sort-amount-up' : 'sort-amount-down'"
              fill="currentColor"
              height="14px"
              width="14px"
            />
            <span>
              <p></p>
              <p>{{ options.find(({ id }) => id === sortParam.text).name }}</p>
            </span>
            <unicon name="angle-down" fill="currentColor" height="13px" width="13px" />
          </div>
        </div>
      </template>
      <template #beforeOptions>
        <div class="menu__content">
          <custom-select
            v-model="sortParam.text"
            :options="options"
            size="small"
            @change="$emit('change', sortParam)"
          />
          <custom-select
            v-model="sortParam.order"
            :options="[
              { id: 'up', name: 'Creciente' },
              { id: 'down', name: 'Decreciente' },
            ]"
            size="small"
            @change="$emit('change', sortParam)"
          />
        </div>
      </template>
      <template #options> </template>
    </Menu>
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu.vue';
import CustomSelect from '@/components/custom/CustomSelect.vue';

export default {
  name: 'SortItem',
  components: {
    Menu,
    CustomSelect,
  },

  model: {
    prop: 'sortParam',
    event: 'change',
  },

  data() {
    return {};
  },

  props: {
    sortParam: {
      type: Object,
    },
    options: {
      type: Array,
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.filter {
  .filter__label {
    border: solid 1px var(--gray-color-900);
    border-radius: 5px;
    color: var(--font-color-300);
    height: 35px;
    padding: 0 0.575rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    svg {
      font-size: 11px;
      color: inherit;
    }
    span {
      display: flex;
      align-items: center;
    }
    &:hover {
      background-color: var(--gray-color-100);
    }
    p {
      font-weight: var(--regular);
      font-size: 0.825rem;
      &:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 7rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-active {
    .filter__label {
      border: solid 1px var(--main-color-300);
      border-radius: 5px;
      color: var(--main-color-500);
      background-color: var(--main-color-000);
      p:nth-child(1) {
        font-weight: var(--semi-bold);
      }
      &:hover {
        color: var(--main-color-500);
        background-color: var(--main-color-100);
      }
    }
  }
}

p {
  color: inherit;
}

.menu__content {
  display: flex;
  flex-flow: column;
  gap: .5rem;
  padding: .5rem;
}
</style>
