<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Personas y puestos', icon: 'user-circle' }"
        />
        <h2>Historial</h2>
      </div>
    </div>

    <div class="filter__container">
      <sort-item
        v-model="sortParam"
        :options="filterCategories"
        @change="filteredLogs.sort(compare)"
      />
      <span class="sort__divider"></span>
      <Filters
        v-if="yearOptions.length"
        @filter="onYearChange($event)"
        :filters="[{ name: 'Año', id: 'year', isDefault: 'true', type: 'year', isRadio: 'true' }]"
        :filtersOptions="yearOptions"
        :isAddBtnEnable="false"
        ref="filters"
      />
      <Filters
        @filter="filterHistory($event)"
        :filters="filterCategories"
        :filtersOptions="filterOptions"
        ref="filters"
      />
    </div>

    <div class="content">
      <table>
        <tbody>
          <tr>
            <th class="number">#</th>
            <th :class="{ sortActive: sortParam.text === 'authorName' }">
              <Menu direction="below" type="table">
                <template #label>
                  <div class="header__label">
                    <p>Usuario</p>
                    <unicon
                      :name="
                        sortParam.text === 'authorName'
                          ? sortParam.order === 'up'
                            ? 'sort-amount-up'
                            : 'sort-amount-down'
                          : 'sort'
                      "
                      fill="currentColor"
                      height="13px"
                      width="13px"
                    />
                  </div>
                </template>
                <template #options>
                  <menu-item @click="changeSortParam('authorName', 'up')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-up"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar creciente
                  </menu-item>
                  <menu-item @click="changeSortParam('authorName', 'down')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-down"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar decreciente
                  </menu-item>
                </template>
              </Menu>
            </th>
            <th :class="{ sortActive: sortParam.text === 'action' }">
              <Menu direction="below" type="table">
                <template #label>
                  <div class="header__label">
                    <p>Acción</p>
                    <unicon
                      :name="
                        sortParam.text === 'action'
                          ? sortParam.order === 'up'
                            ? 'sort-amount-up'
                            : 'sort-amount-down'
                          : 'sort'
                      "
                      fill="currentColor"
                      height="13px"
                      width="13px"
                    />
                  </div>
                </template>
                <template #options>
                  <menu-item @click="changeSortParam('action', 'up')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-up"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar creciente
                  </menu-item>
                  <menu-item @click="changeSortParam('action', 'down')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-down"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar decreciente
                  </menu-item>
                </template>
              </Menu>
            </th>
            <th :class="{ sortActive: sortParam.text === 'date' }">
              <Menu direction="below" type="table">
                <template #label>
                  <div class="header__label">
                    <p>Fecha</p>
                    <unicon
                      :name="
                        sortParam.text === 'date'
                          ? sortParam.order === 'up'
                            ? 'sort-amount-up'
                            : 'sort-amount-down'
                          : 'sort'
                      "
                      fill="currentColor"
                      height="13px"
                      width="13px"
                    />
                  </div>
                </template>
                <template #options>
                  <menu-item @click="changeSortParam('date', 'up')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-up"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar creciente
                  </menu-item>
                  <menu-item @click="changeSortParam('date', 'down')">
                    <unicon
                      width="16px"
                      height="16px"
                      name="sort-amount-down"
                      fill="var(--font-color-700)"
                    ></unicon>
                    Ordenar decreciente
                  </menu-item>
                </template>
              </Menu>
            </th>
            <th>Detalles</th>
          </tr>
          <template v-if="filteredLogs.length && selectedYear && !isLoading">
            <tr v-for="(log, index) in filteredLogs" :key="log.id">
              <td class="number">{{ index + 1 }}</td>
              <td>{{ log.authorName }}</td>
              <td>
                <span class="log__action" :class="log.action">{{ log.action }}</span>
              </td>
              <td class="log__date">
                <div>
                  <span>
                    {{ timeStampToDMYOffset(log.createdOn) }}
                  </span>
                  <span>
                    {{ new Date(log.createdOn.seconds * 1000).toLocaleTimeString() }}
                  </span>
                </div>
              </td>
              <td>
                <span class="log__content" @click="viewDetails(log.content)">Ver detalles</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <empty-state
        v-if="!isLoading && (!filteredLogs.length || !selectedYear)"
        label="cambios"
        :haveAction="false"
      />
      <div v-if="isLoading" class="loading"><loading-spinner /></div>
    </div>
    <div class="logs__count">
      <p>{{ history.length }} cambios</p>
    </div>

    <Modal ref="modal__details" size="sm">
      <template #title>Detalles</template>
      <template #content>
        <div class="modal__details">
          <p>{{ selectedContent }}</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { faSort, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import Filters from '@/components/filters/Filters.vue';
import SortItem from '@/components/SortItem.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Modal from '@/components/Modal.vue';
import EmptyState from '@/components/EmptyState.vue';
import { timeStampToDMYOffset, timeStampToYM } from '@/dateFormats';

export default {
  components: {
    LoadingSpinner,
    Breadcrumbs,
    Modal,
    Menu,
    MenuItem,
    Filters,
    SortItem,
    EmptyState,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        sort: faSort,
        sortDown: faArrowDown,
        sortUp: faArrowUp,
      },
      sortParam: { text: 'date', order: 'down' },
      selectedContent: '',
      selectedYear: '',
      timeStampToDMYOffset,
      timeStampToYM,
      filterCategories: [
        { id: 'user', name: 'Usuario', type: 'text' },
        { id: 'action', name: 'Acción', type: 'select' },
        { id: 'month', name: 'Mes', type: 'month' },
        { id: 'date', name: 'Fecha', type: 'text' },
      ],
      filterOptions: [
        {
          name: 'Actualizar',
          id: 'update',
          categoryId: 'action',
        },
        {
          name: 'Editar',
          id: 'edit',
          categoryId: 'action',
        },
        {
          name: 'Agregar',
          id: 'add',
          categoryId: 'action',
        },
        {
          name: 'Eliminar',
          id: 'delete',
          categoryId: 'action',
        },
      ],
      filteredLogs: [],
      yearOptions: [],
      lastFilters: [],
    };
  },

  async mounted() {
    try {
      const d = new Date();
      this.selectedYear = d.getFullYear().toString();
      for (let index = 2022; index <= d.getFullYear(); index += 1) {
        this.yearOptions.push({
          name: index.toString(),
          id: index.toString(),
          active: index === d.getFullYear(),
          categoryId: 'year',
        });
      }

      await this.fetchHistory(d.getFullYear());
      this.filterHistory();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    ...mapActions('history', ['fetchHistory']),
    ...mapMutations(['setAlert']),

    filterHistory(filters = []) {
      this.lastFilters = filters;
      const activeFilters = [];
      let logs = JSON.parse(JSON.stringify(this.history));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.name);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });
      logs = this.history.filter((log) => {
        for (let index = 0; index < activeFilters.length; index += 1) {
          const filter = activeFilters[index];
          let logValue = '';
          if (filter.id === 'action') logValue = log.action.toUpperCase();
          else if (filter.id === 'month') {
            logValue = this.timeStampToYM(log.createdOn);
            console.log(logValue);
          } else if (filter.id === 'date') {
            logValue = this.timeStampToYMD(log.createdOn);
          } else logValue = log.authorName;
          if (filter.type === 'text') return logValue.includes(filter.options[0]);
          return filter.options.includes(logValue);
        }
        return true;
      });

      if (this.sortParam.text) {
        this.filteredLogs = logs.sort(this.compare);
      } else this.filteredLogs = logs;
    },

    async onYearChange(e) {
      const d = e[0].options.find(({ active }) => active);
      if (!d) this.selectedYear = null;
      else this.selectedYear = d.id;
      if (d) {
        this.isLoading = true;
        const date = new Date(`${this.selectedYear}-1-1`);
        await this.fetchHistory(date.getFullYear());
        this.filterHistory(this.lastFilters);
        this.isLoading = false;
      }
    },

    viewDetails(content) {
      this.selectedContent = content;
      this.openDetailsModal();
    },

    openDetailsModal() {
      this.$refs.modal__details.open();
    },

    changeSortParam(text, direction) {
      this.sortParam.order = direction;
      this.sortParam.text = text;
      if (this.sortParam.text) {
        this.filteredLogs = this.history.sort(this.compare);
      }
    },
    compare(a, b) {
      const isOrderUp = this.sortParam.order === 'up';
      let valueA = a[this.sortParam.text] || '';
      let valueB = b[this.sortParam.text] || '';
      if (this.sortParam.text === 'date') {
        valueA = a.createdOn.seconds;
        valueB = b.createdOn.seconds;
      }
      if (valueA < valueB) {
        return isOrderUp ? -1 : 1;
      }
      if (valueA > valueB) {
        return isOrderUp ? 1 : -1;
      }
      return 0;
    },
  },

  computed: {
    ...mapState({
      history: (state) => state.history.history,
    }),
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.5rem;
  }

  .history__month-input {
    width: 12rem;
  }
}

.content {
  width: 100%;
  overflow: auto;
  background-color: white;
}

.logs__count {
  display: flex;
  justify-content: flex-end;
  padding: 1em 0.5em 0 0;
  p {
    font-size: 0.81em;
  }
}

.header__label {
  display: flex;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: var(--gray-color-200);
  }
}

table {
  width: 100%;
  .log__content {
    cursor: pointer;
    color: var(--main-color-500);
    &:hover {
      text-decoration: underline var(--main-color-500);
    }
  }
  .log__action {
    padding: 0.375rem;
    font-size: 0.75rem;
    font-weight: var(--medium);
    color: var(--font-color-500);
    background-color: var(--gray-color-200);
    border-radius: 4px;
    text-align: center;
    &.Editar {
      color: var(--success-color-600);
      background-color: var(--success-color-100);
    }
    &.Agregar {
      color: var(--success-color-600);
      background-color: var(--success-color-100);
    }
    &.Eliminar {
      color: var(--danger-color-500);
      background-color: var(--danger-color-100);
    }
    &.Actualizar {
      color: var(--main-color-500);
      background-color: var(--main-color-100);
    }
  }

  .log__date div {
    display: flex;
    flex-flow: column;

    span:nth-child(2) {
      font-size: 0.7rem;
      color: var(--font-color-100);
    }
  }

  tr:first-child {
    th {
      &.sortActive,
      &.sortActive * {
        color: var(--font-color-900);
      }
    }
  }

  th,
  td {
    padding: 0.8rem 2rem 0.8rem 0.8rem;
  }

  th:not(:first-child):not(:nth-child(5)) {
    padding: 0.8rem 1.5rem 0.8rem 0.3rem;
  }

  td:not(:first-child):not(:last-child) {
    width: 35%;
  }

  th:first-child,
  td:first-child {
    padding: 0.8rem;
  }
}

.loading {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__details {
  padding: 1rem 2.5rem 1.5rem;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    color: var(--font-color-800);
    font-weight: var(--regular);
    white-space: pre-line;
    overflow-y: auto;
    max-height: 20rem;
    line-height: 1.5rem;
  }
}

.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.sort__divider {
  width: 1px;
  height: 30px;
  background-color: var(--gray-color-700);
  margin: 0 0.5rem;
}
</style>
